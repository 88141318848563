import SubCategories from "../../component/SubCategories";
import './Subcategories.css'
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {SearchBar} from "../../component/search/SearchBar";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Skeleton} from "@mui/material";
import Footer from "../../component/Footer/Footer";

export const Subcategories = () => {

    const backendApiUrl = process.env.REACT_APP_BASE_API_URL
    const backendUrl = process.env.REACT_APP_BACKEND_URL

    const {categoryId} =useParams();
    const [image, setImage] = useState(sessionStorage.getItem('image') ?? '');
    const [restaurantCode, setRestaurantCode] = useState(sessionStorage.getItem('restaurantCode') ?? '');

    const [itemOrderQuantity, setItemOrderQuantity] = useState(Number);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false)

    const initialRestaurantState = {
        thumbnail: ""
    };

    const [restaurantDetails, setRestaurantDetails] = useState(initialRestaurantState);

    const navigate = useNavigate();
    const navigateToOrderPage = () =>{
        navigate('/order')
    }
    const goBack = () => {
        navigate(-1);
    }
    const handleSubChanged= () =>{
        navigate('/menu')
    }

    const handleSearch = async (search:string) => {
        try {
            const response = await axios.get(backendApiUrl + `global-search/` + restaurantCode +`?search=${search}`);
            setResults(response.data.results);
        } catch (error) {
        }
    };

    const getRestaurantInfo = async () => {
        setLoading(true)
        return (axios.get(backendApiUrl + 'restaurant/' + restaurantCode)
                .then((response) => {
                    setRestaurantDetails({
                        thumbnail: response.data.data.thumbnail
                    });
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                })
        )
    };
    useEffect(() => {
        sessionStorage.setItem('id', JSON.stringify(categoryId));
        sessionStorage.getItem('--my-color')
        const items = sessionStorage.getItem('previous_items')  ? JSON.parse(sessionStorage.getItem('previous_items') ?? "") : [];
        if (items.length > 0) {
            setItemOrderQuantity(items.length);
        }

        getRestaurantInfo();
    }, [])

    return (
        <div id="home">
            <div className="container-fluid pb-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-lg-4 px-0">
                        {loading ? (
                        <> </>
                        ) : (
                            <img src={backendUrl +  restaurantDetails.thumbnail} alt="" className="sliderImage"/>
                        )}
                        <div className="slider-content">
                            <div onClick={goBack} className="back mt-3">
                                <img src='/svgs/back.svg' alt=""/>
                            </div>
                            <h1 className="pt-4 text-center"> </h1>
                            {loading ? (
                                <></>
                            ) : (
                           <div className="mt-2">
                               <button type="button" value="" className="mt-4 cart-bg cart" onClick={()=>navigateToOrderPage()}> <img src='/svgs/basket.svg' alt=""/>
                                   <span className="badge"> {itemOrderQuantity}</span>
                               </button>
                           </div>
                            )}
                        </div>
                        <div className="px-3 mt-4">
                            {loading ? (
                                <></>
                            ) : (
                                <SearchBar/>
                            )}

                            {!loading &&
                            <h2 className="px-0 pb-3 pt-5"> SUB CATEGORIES</h2>
                            }
                            <div className="container-fluid px-0">
                                <div className="row d-flex align-items-baseline">
                                    {!loading &&
                                    <SubCategories onSubChange={handleSubChanged}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
export default Subcategories
