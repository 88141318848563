import {BrowserRouter, Routes, Route,} from "react-router-dom";
import SubCategories from "../pages/SubCategoryPage/Subcategories";
import {RequiresAuthentication} from "./ProtectedRoutes";
import Unauthorized from "../pages/ErrorHandeling/401";
import PageNotFound from "../pages/ErrorHandeling/404";
import Menu from "../pages/MenuPage/Menu"
import ItemDetails from "../pages/ItemDetailsPage/ItemDetails";
import Order from "../pages/OrderPage/Order";
import MainCategory from "../pages/MainCategoriesPage/MainCategory";

function AppRouter() {
    const primaryColor = localStorage.getItem('primaryColor');
    const secondaryColor = localStorage.getItem('secondaryColor');

    if (primaryColor) {
        document.documentElement.style.setProperty('--my-color', primaryColor);
    }
    if (secondaryColor) {
        document.documentElement.style.setProperty('--my-color-2', secondaryColor);
    }
    return (
        <BrowserRouter>
            <Routes>
                    <Route path="401" element={<Unauthorized/>}/>
                    <Route>
                        <Route path="/:restaurantCode" element={<MainCategory/>}/>
                        <Route path="/subCategory" element={<SubCategories/>}/>
                        <Route path="/menu" element={<Menu/>}/>
                        <Route path="/itemDetails" element={<ItemDetails/>}/>
                        <Route path="/order" element={<Order/>}/>
                    </Route>
                    <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter
