import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Skeleton} from "@mui/material";
import ItemDetails from "../../pages/ItemDetailsPage/ItemDetails";

export interface IUpgrade {
    ingredient: string;
    price: any;
    name: string;
}

export interface ISide {
    name: string;
    price: any;
}

export const SpecialInstructions = (Props: {
    upgrades: any;
    itemDetails: any;
    removeIngredients: any;
    sides: any;
    sauces: any;
}) => {
    // const [total, setTotal] = useState(parseInt(Props.itemDetails.price));

    const [total, setTotal] = useState(
        parseFloat(Props.itemDetails.price.toString())
    );
    const [quantity, setQuantity] = useState(1);
    const [upgrades, setUpgrades] = useState(Array<IUpgrade>);
    const [sides, setSides] = useState(Array<ISide>)
    const [removes, setRemoves] = useState(Array<string>);
    const [instructions, setInstructions] = useState("");
    const [order, setOrder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sauces, setSauces] = useState<any[]>([]);
    const hasInstruction = sessionStorage.getItem('has_instruction');
    const showTextArea = hasInstruction === '1';

    const handleChangeUpgrade = (
        event: any,
        upgrade: { id: number; ingredient: string; price: any, name: any }
    ) => {
        const checkboxPrice = parseFloat(upgrade.price);
        if (event.target.checked) {
            let data = [...upgrades];
            data.push(upgrade);
            setUpgrades(data);
            let addedPrice = 0;
            for (let i = 0; i < data.length; i++) {
                addedPrice = addedPrice + parseFloat(data[i].price);
            }
            setTotal((addedPrice + parseFloat(Props.itemDetails.price)) * quantity);
        } else {
            let index = upgrades.findIndex(
                (value) => value.ingredient === upgrade.ingredient
            );
            let data = [...upgrades];
            data.splice(index, 1);
            setUpgrades(data);
            setTotal(total - checkboxPrice * quantity);
        }
    };
    const handleChangeSide = (
        event: any,
        side: { id: number; name: string; price: any }
    ) => {
        const checkboxPrice = parseFloat(side.price);
        if (event.target.checked) {
            let data = [...sides];
            data.push(side);
            setSides(data);
            let addedPrice = 0;
            for (let i = 0; i < data.length; i++) {
                addedPrice = addedPrice + parseFloat(data[i].price);
            }
            setTotal((addedPrice + parseFloat(Props.itemDetails.price)) * quantity);
        } else {
            let index = sides.findIndex(
                (value) => value.name === side.name
            );
            let data = [...sides];
            data.splice(index, 1);
            setSides(data);
            setTotal(total - checkboxPrice * quantity);
        }
    };
    const handleChangeSauce = (
        event: any,
        sauce: { id: number; sauce_name: string; price: string }
    ) => {
        const checkboxPrice = parseFloat(sauce.price);
        let data = [...sauces];

        if (event.target.checked) {
            data.push(sauce);
        } else {
            const index = sauces.findIndex((value) => value.sauce_name === sauce.sauce_name);
            data.splice(index, 1);
        }
        let addedSaucePrice = 0;
        for (let i = 0; i < data.length; i++) {
            addedSaucePrice += parseFloat(data[i].price);
        }
        const itemBasePrice = parseFloat(Props.itemDetails.price);
        const totalPrice = (itemBasePrice + addedSaucePrice) * quantity;
        setSauces(data);
        setTotal(totalPrice);
    };
    const handleChangeRemove = (event: any, remove: { ingredient: string }) => {
        if (event.target.checked) {
            let data = [...removes];
            data.push(remove.ingredient);
            setRemoves(data);
        } else {
            let index = upgrades.findIndex(
                (value) => value.ingredient === remove.ingredient
            );
            let data = [...removes];
            data.splice(index, 1);
            setRemoves(data);
        }
    };

    const increment = (upgrade: any) => {
        if (quantity < 10) {
            setQuantity((quantity) => quantity + 1);
            let data = [...upgrades, ...sides];
            let myValues = 0;
            for (let i = 0; i < data.length; i++) {
                myValues = myValues + parseFloat(data[i].price);
            }
            if (!isNaN(myValues)) {
                setTotal(
                    (myValues + parseFloat(Props.itemDetails.price)) * (quantity + 1)
                );
            } else {
                setTotal(parseFloat(Props.itemDetails.price) * (quantity + 1));
            }
        }
    };
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            let data = [...upgrades, ...sides];
            let myValues = 0;
            for (let i = 0; i < data.length; i++) {
                myValues = myValues + parseFloat(data[i].price);
            }
            if (!isNaN(myValues)) {
                setTotal(
                    (myValues + parseFloat(Props.itemDetails.price)) * (quantity - 1)
                );
            } else {
                setTotal(parseFloat(Props.itemDetails.price) * (quantity - 1));
            }
        }
    };

    const addToOrder = () => {
        // const previousItems = []
        // if (previousItems.length > 0) {
        //     JSON.parse( localStorage.getItem('previous_items') ?? "") ;
        // }
        const previousItems = sessionStorage.getItem("previous_items")
            ? JSON.parse(sessionStorage.getItem("previous_items") ?? "")
            : [];
        // const previousItems = JSON.parse( localStorage.getItem('previous_items') ?? "") ?? []
        const object = {
            item_name: Props.itemDetails.title,
            quantity: quantity,
            total_price: total,
            special_instructions: instructions,
            upgrades: upgrades,
            removes: removes,
        };
        previousItems.push(object);
        sessionStorage.setItem("previous_items", JSON.stringify(previousItems));
        navigate("/menu");
    };
    const navigate = useNavigate();
    const navigateToOrderPage = () => {
        navigate("/order");
    };

    return (
        <div className="special-instructions mt-4">
            {loading ? (
                <></>
            ) : (
                <>
                    {Props.upgrades.length > 0 ? (
                        <h2 className="pt-5 px-4 pb-2">Upgrade </h2>
                    ) : null}
                </>
            )}
            {Props.upgrades.map(
                (upgrade: { id: number; ingredient: string; price: string, name: any }) => {
                    return (
                        <div className="px-4 py-2">
                            <div className="d-flex align-items-center justify-content-between">
                                {loading ? (
                                    <></>
                                ) : (
                                    <h4 className="mb-0">{upgrade.ingredient}</h4>
                                )}
                                <div className="ingredient-price d-flex align-items-center">
                                    <p className="mb-0 px-3 ingredient-price">
                                        $ {upgrade.price}
                                    </p>
                                    <label className="checkbox-section mb-4">
                                        <input
                                            type="checkbox"
                                            name={upgrade.ingredient}
                                            value={upgrade.ingredient}
                                            onChange={(event) => handleChangeUpgrade(event, upgrade)}
                                        />
                                        <span className="checkmark"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    );
                }
            )}
            {loading ? (
                <></>
            ) : (
                <>
                    {Props.sides.length > 0 ? (
                        <h2 className="pt-5 px-4 pb-2">Side </h2>
                    ) : null}
                </>
            )}
            {Props.sides.map(
                (side: { id: number; name: string; price: string }) => {
                    return (
                        <div className="px-4 py-2">
                            <div className="d-flex align-items-center justify-content-between">
                                {loading ? (
                                    <></>
                                ) : (
                                    <h4 className="mb-0">{side.name}</h4>
                                )}
                                <div className="ingredient-price d-flex align-items-center">
                                    <p className="mb-0 px-3 ingredient-price">
                                        $ {side.price}
                                    </p>
                                    <label className="checkbox-section mb-4">
                                        <input
                                            type="checkbox"
                                            name={side.name}
                                            value={side.name}
                                            onChange={(event) => handleChangeSide(event, side)}
                                        />
                                        <span className="checkmark"/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    );
                }
            )}
            {Props.sauces.length > 0 ? (
                <h2 className="pt-5 px-4 pb-2">Sauces</h2>
            ) : null}
            {Props.sauces.map((sauce: { id: number; sauce_name: string; price: string }) => {
                return (
                    <div className="px-4 py-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">{sauce.sauce_name}</h4>
                            <div className="ingredient-price d-flex align-items-center">
                                <p className="mb-0 px-3 ingredient-price">
                                    $ {sauce.price}
                                </p>
                                <label className="checkbox-section mb-4">
                                    <input
                                        type="checkbox"
                                        name={sauce.sauce_name}
                                        value={sauce.sauce_name}
                                        onChange={(event) => handleChangeSauce(event, sauce)}
                                    />
                                    <span className="checkmark"/>
                                </label>
                            </div>
                        </div>
                    </div>
                );
            })}
            {/*{subTitles.items.length > 0 ? <h3 className="mb-0 mt-5">{subTitles.title}</h3> : null}*/}
            {Props.removeIngredients.length > 0 ? (
                <h2 className="pt-5 px-4 pb-2">Remove Ingredients </h2>
            ) : null}
            {Props.removeIngredients.map((remove: { ingredient: string }) => {
                return (
                    <div className="px-4 py-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">{remove.ingredient}</h4>
                            <div className="ingredient-price d-flex align-items-center">
                                <label className="checkbox-section mb-4">
                                    <input
                                        type="checkbox"
                                        onChange={(event) => handleChangeRemove(event, remove)}
                                    />
                                    <span className="checkmark"/>
                                </label>
                            </div>
                        </div>
                    </div>
                );
            })}
            {showTextArea && (
                <div className="container-fluid">
                    <div className="row notes mt-4 px-4 mb-4 pb-3">
                        <h2 className="pt-5 px-0">Any Special Instructions</h2>
                        <textarea
                            name="postContent"
                            rows={4}
                            placeholder="Tell us here."
                            className="col-md-12 form-control"
                            onChange={(value) => setInstructions(value.target.value)}
                        />
                    </div>
                </div>
            )}
            <div className="finalize-order px-2 py-4">
                <div className="container-fluid px-3">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-6 col-md-7">
                            <div className="input-group py-2 px-3">
                                <div className="input-group-prepend">
                                    <button className="" type="button" onClick={decrement}>
                                        -
                                    </button>
                                </div>
                                <input
                                    type="text"
                                    className="form-control quantity-value"
                                    value={quantity}
                                />
                                <div className="input-group-prepend">
                                    <button
                                        className=""
                                        type="button"
                                        onClick={() => increment(quantity)}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="add-order col-6 col-md-5"
                            onClick={() => addToOrder()}
                        >
                            <div className="total-item text-center py-2">
                                <p className="mb-0 fw-bold">Add to Order</p>
                                <p className="pt-0 mb-0 total">
                                    $
                                    {total == 0
                                        ? parseFloat(Props.itemDetails.price)
                                        : total.toFixed(1)}
                                </p>
                            </div>
                        </button>
                    </div>
                    <div onClick={() => navigateToOrderPage()}>
                        <div className="row pt-3 px-3 finalize-button">
                            <button type="button" className="py-3">
                                Finalize Order
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
};

export default SpecialInstructions;
