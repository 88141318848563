import './error.css'
import Footer from "../../component/Footer/Footer";

export const PageNotFound = () => {
    return (
        <div className="error ustify-content-center">
            <h2>Klev E-Menu</h2>
            <div className="row d-flex justify-content-center pt-5">
                <img src='/svgs/Klev-clover.svg' alt="" className="clover"/>
            </div>
            <div className="d-flex justify-content-center pt-5">
                <Footer/>
            </div>
        </div>

    )
}
export default PageNotFound
