import {styled} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';
import {SearchBarStyle} from "./SearchBarStyle";

const CustomSearch = styled(Autocomplete)({
        ...SearchBarStyle
    }
);
export interface ISearchResult{
    item_name: string,
    id: number
}

export  const SearchBar = () =>  {
    const navigate = useNavigate();
    const backendApiUrl = process.env.REACT_APP_BASE_API_URL

    const [restaurantCode, setRestaurantCode] = useState(sessionStorage.getItem('restaurantCode') ?? '');
    const [loading  , setLoading] = useState(false)
    const [results, setResults] = useState([]);
    const [searchInput , setSearchInput] = useState("");
    const [searchItems , setSearchItems] = useState([])
    const [open , setOpen] = useState(false)


    const handleSearch = async (search:string) => {
    if(search){
        setLoading(true)
        try {
            const response = await axios.get(backendApiUrl + `global-search/` + restaurantCode +`?search=${search}`);
            setSearchItems(response.data.data);
            
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }
    };

    const redirectToItem = (item : any) =>{
        sessionStorage.setItem('itemId' , item.id)
        sessionStorage.setItem('categoryId' , item.title ? item.title.header.category.main_category_id : item.header.category.main_category_id)
        sessionStorage.setItem('subCategoryId' ,item.title ? item.title.header.category.id : item.header.category.id)
        sessionStorage.setItem('subCategoryTitle' ,item.title ?  item.title.header.category.title : item.header.category.title)
        navigate('/itemDetails')
    }
    useEffect(() => {
        if(searchInput){
            const timer = setTimeout(() => {
                handleSearch(searchInput);
            }, 500);
            return () => {
                clearTimeout(timer);
            };
        }else{
            handleSearch('');
        }

    }, [searchInput]);

    return (
    <CustomSearch
        disablePortal
        id="combo-box-demo"
        noOptionsText={"Search for items"}
        options={searchItems ?? []}
        role="list-box"
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        sx={{ width: '100%' }}
        onChange={(event: any, newValue: any | null) => {
            redirectToItem(newValue)
        }}
        getOptionLabel={(option : any) => option.item_name}
        onInputChange={(event, newInputValue) => {
            setSearchInput(newInputValue)
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Search"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> :
                                params.InputProps.endAdornment
                            }
                        </React.Fragment>
                    ),
                }}
            />
        )}
    />
    );
}
