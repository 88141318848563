import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IMainCategory, InitialMainCategoryState} from "./types";

export const initialState: InitialMainCategoryState = {
    loading: false,
    mainCategories : []

}

export const mainCategories = createSlice({
    name: 'mainCategories',
    initialState,
    reducers: {
        // todo : add your reducers to change and manipulate the state
        setMainCategoriesLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setMainCategoriesData(state , action: PayloadAction<Array<IMainCategory>>){
            state.mainCategories = action.payload;

        }
    }
})

export default mainCategories.reducer;
