import './MainCategory.css'
import Categories from "../../component/Categories";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {SearchBar} from "../../component/search/SearchBar";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Footer from "../../component/Footer/Footer";

export const MainCategory = () => {

    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const backendApiUrl = process.env.REACT_APP_BASE_API_URL
    const [itemOrderQuantity, setItemOrderQuantity] = useState(Number);
    const [loading, setLoading] = useState(false)
    const [color, setColor] = useState('');

    const {restaurantCode} = useParams();

    const {thumbnail} = useParams();
    const {primary_color} = useParams();

    const [code, setCode] = useState("")

    const [restaurantName, setRestaurantName] = useState("")
    const [logo, setLogo] = useState("")

    const initialRestaurantState = {
        thumbnail: "",
        restaurantName: "",
        logo: ""
    };
    const navigate = useNavigate();
    const navigateToOrderPage = () => {
        navigate('/order')
    }

    const [restaurantDetails, setRestaurantDetails] = useState(initialRestaurantState);

    const setCodeValue = () => {
        setCode(restaurantCode as string)
        sessionStorage.setItem('restaurantCode', restaurantCode as string)
    }

    const getRestaurantInfo = async () => {
        setLoading(true)
        return (axios.get(backendApiUrl + 'restaurant/' + restaurantCode)
                .then((response) => {
                    setRestaurantDetails({
                        thumbnail: response.data.data.thumbnail,
                        restaurantName: response.data.data.name,
                        logo: response.data.data.logo
                    });
                    // setColor(response.data.data.primary_color);
                    // setColor(response.data.data.secondary_color)
                    // document.documentElement.style.setProperty('--my-color', response.data.data.primary_color);
                    // document.documentElement.style.setProperty('--my-color-2', response.data.data.secondary_color);

                    const primaryColor = response.data.data.primary_color;
                    const secondaryColor = response.data.data.secondary_color;

                    localStorage.setItem('primaryColor', primaryColor);
                    localStorage.setItem('secondaryColor', secondaryColor);

                    document.documentElement.style.setProperty('--my-color', primaryColor);
                    document.documentElement.style.setProperty('--my-color-2', secondaryColor);

                    sessionStorage.setItem('has_instruction', response.data.data.has_instruction.toString());
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                })
        )
    };

    useEffect(() => {

        const storedRestaurantCode = sessionStorage.getItem('restaurantCode');

        if (storedRestaurantCode !== restaurantCode) {
            sessionStorage.clear();
            if (typeof restaurantCode === "string") {
                sessionStorage.setItem('restaurantCode', restaurantCode);
            }
        }
        // sessionStorage.setItem('logo' , restaurantLogo)
        setCodeValue();

        const items = sessionStorage.getItem('previous_items') ? JSON.parse(sessionStorage.getItem('previous_items') ?? "") : [];
        if (items.length > 0) {
            setItemOrderQuantity(items.length);
        }
        sessionStorage.setItem('--my-color', color as any)
        sessionStorage.setItem('--my-color-2', color as any)

        getRestaurantInfo();
    }, [])

    return (

        <div>
            <div className="container-fluid">
                <div className="row d-flex justify-content-center pb-5">
                    <div className="col-12 col-lg-4 px-0">
                        {loading ? (
                            <> </>
                        ) : (
                            <img src={backendUrl + restaurantDetails.thumbnail} alt="" className="sliderImage"/>
                        )}
                        {/*<img src="/images/slider.png" alt="" className="sliderImage"/>*/}
                        <p> {primary_color} </p>
                        <div className="slider-content">
                            {loading ? (
                                <></>
                            ) : (
                                <div className="mt-2">
                                    <button type="button" value="" className="mt-3 cart cart-bg"
                                            onClick={() => navigateToOrderPage()}><img src='/svgs/basket.svg' alt=""/>
                                        <span className="badge"> {itemOrderQuantity}</span>
                                    </button>
                                </div>
                            )}

                        </div>
                        <div className="mt-5 px-3">
                            {!loading &&
                                <SearchBar/>
                            }
                            {
                                code &&
                                <Categories/>
                            }
                        </div>
                    </div>
                    {!loading &&
                        <Footer/>
                    }
                </div>
            </div>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    )
}
export default MainCategory
