export const SearchBarStyle = {
    '& label': {
        color: '#1C4239',
        fontStyle:'italic'
    },
    '& label.Mui-focused': {
        color: '#1C4239',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#1C4239',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius:'40px',
        '& fieldset': {
            borderColor: '#1C4239',
        },
        '&:hover fieldset': {
            borderColor: '#1C4239',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#1C4239',
        },
    },
}
