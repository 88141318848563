import React, {FC} from 'react';
import './App.css';
import {useAppSelector} from "./store/redux";
import AppRouter from "./routes/AppRouter";
import 'bootstrap/dist/css/bootstrap.min.css';

export  const App: FC = () => {
    const token  = useAppSelector(state => state.authReducer.token);
    return (
        <>
            <AppRouter />
        </>
  );
}

