import './Order.css'
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal, Button } from 'react-bootstrap';
interface OrderItem {
    total_price: number;
    quantity:string,
    item_name:string
    special_instructions:string
    upgrades:any
    removes:any
}
export const Order = () => {

    const backendApiUrl = process.env.REACT_APP_BASE_API_URL
    const backendUrl = process.env.REACT_APP_BACKEND_URL

    const [orderItems, setOrderItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(Number);

    const [items, setItems] = useState<OrderItem[]>([]);
    const [color, setColor] = useState('');
    // const [previousItems, setPreviousItems] = useState([]);
    const [restaurantCode, setRestaurantCode] = useState(sessionStorage.getItem('restaurantCode') ?? '');
    const [loading, setLoading] = useState(false)

    const initialRestaurantState = {
        name:"",
        logo:""
    };

    const [restaurantDetails, setRestaurantDetails] = useState(initialRestaurantState);

    useEffect(() => {
        const items = sessionStorage.getItem("previous_items");
        if (items) {
            const data = JSON.parse(items);
            setItems(data);
        }
    }, []);

    useEffect(() => {
        getRestaurantInfo();

        const items = sessionStorage.getItem('previous_items') ;
        if (items) {
            const allItems: OrderItem[] = JSON.parse(items);
            const itemPrices = allItems.map((item: OrderItem) => item.total_price);
            setItems(allItems);
            const sum = itemPrices.reduce((acc, curr) => {
                return acc + curr;
            }, 0);
            setTotalAmount(sum)
        }
    }, []);

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const getRestaurantInfo = async () => {
        return (axios.get(backendApiUrl + 'restaurant/' + restaurantCode)
                .then((response) => {
                    setRestaurantDetails({
                        name:response.data.data.name,
                        logo: response.data.data.logo
                    });
                })
                .catch((error) => {
                })
        )
    };
    // const handleDelete = (itemToDelete:any) => {
    //     const updatedItems = items.filter((item) => item !== itemToDelete);
    //     setItems(updatedItems);
    //
    //     sessionStorage.setItem('previous_items', JSON.stringify(updatedItems));
    //
    //     // Recalculate total amount
    //     const itemPrices = updatedItems.map((item) => item.total_price);
    //     const sum = itemPrices.reduce((acc, curr) => acc + curr, 0);
    //     setTotalAmount(sum);
    // };

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    const handleDelete = (itemToDelete:any) => {
        setItemToDelete(itemToDelete);
        setShowConfirmModal(true);
    };

    const confirmDelete = () => {
        const updatedItems = items.filter((item) => item !== itemToDelete);
        setItems(updatedItems);

        // Update local storage after delete item
        sessionStorage.setItem('previous_items', JSON.stringify(updatedItems));

        // Recalculate total amount
        const itemPrices = updatedItems.map((item) => item.total_price);
        const sum = itemPrices.reduce((acc, curr) => acc + curr, 0);
        setTotalAmount(sum);

        setShowConfirmModal(false);
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
    };

    return (
        <div>
            <div className="container-fluid pt-4 py-5 overflow-hidden">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-lg-4 px-0">
                        <div className="d-flex justify-content-between align-items-center px-3">
                            <div onClick={() => goBack()}>
                                <img src="/svgs/back-black.svg" alt=""/>
                            </div>
                            <p className="mb-0 order"> My Order</p>
                            {restaurantDetails.logo.length > 0 ?
                            <Link to="/subCategory">
                            <img src={backendUrl +  restaurantDetails.logo} alt="" className="logo"/>
                            </Link> : null}
                        </div>

                        <div className="details px-3">
                            <h2 className="mb-0">{restaurantDetails.name}</h2>
                            <div className="border-bottom-title"/>
                        </div>
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between align-items-center pt-3 px-4">
                                <div className="col-md-2"></div>
                                <div className="col-md-2 col-3 px-0">
                                    <p className="mb-0"> Qty </p>
                                </div>
                                <div className="col-md-6 col-6 px-0">
                                    <p className="mb-0">Item</p>
                                </div>
                                <div className="col-md-2 col-3 px-0">
                                    <p className="mb-0 d-flex justify-content-end"> Price </p>
                                </div>
                            </div>
                        </div>
                        {items.map((orderItem) => {
                            return (
                                <div>
                                    <Modal show={showConfirmModal} onHide={cancelDelete}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{orderItem.item_name}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Do you want to delete this item?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={cancelDelete}>No</Button>
                                            <Button variant="danger" onClick={confirmDelete}>Yes</Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <div className="bg-white mt-2 px-0 mx-1">
                                        <div className="d-flex justify-content-between align-items-center pt-3 px-2">
                                            <div className="">
                                                <button type="button" className="delete" onClick={() => handleDelete(orderItem)}>
                                                    <img src="/images/delete.png" width="30" height="30" alt="Delete" />
                                                </button>
                                            </div>
                                            <div className="col-md-1 col-1 px-0">
                                                <p className="mb-0 quantity">  {orderItem.quantity} </p>
                                            </div>
                                            <div className="col-md-6 col-6 px-0">
                                                <h4 className="mb-0"> {orderItem.item_name}</h4>
                                            </div>
                                            <div className="col-md-2 col-2 px-0">
                                                <p className="mb-0 total-item-price d-flex justify-content-end"> $ {orderItem.total_price.toFixed(2)} </p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="col-md-6 col-6 px-0 mt-2">
                                                {orderItem.upgrades.length > 0 || orderItem.removes.length >0 ||  orderItem.special_instructions.length > 0 ? (
                                                    <ul className="bg-details pt-2 px-4 py-3">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                {orderItem.upgrades.length > 0 ? <li className="pt-1"> Upgrade:</li> : null}
                                                            </div>
                                                            <div className="col-md-8">
                                                                {orderItem.upgrades.map((upgrade: { ingredient: any }) => (<p className="mb-0">{upgrade.ingredient}</p>))}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                {orderItem.removes.length > 0 ? <li className="pt-1"> Remove:</li> : null}
                                                            </div>
                                                            <div className="col-md-8">
                                                                {orderItem.removes.map((remove: any) => (<p className="mb-0">{remove}</p>))}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                {orderItem.special_instructions.length > 0 ? <li className="pt-1">Special Instructions</li> : null}
                                                            </div>
                                                            <div className="col-md-8">
                                                                {orderItem.special_instructions ? <p className="pt-1"> {orderItem.special_instructions}</p> : null}
                                                            </div>
                                                        </div>
                                                    </ul>
                                                ) : null}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            );
                        })}
                        <div className="pt-4 mx-4 mt-4 d-flex justify-content-between subtotal">
                            <h2> Total</h2>
                            <p> $ {totalAmount.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    )
}
export default Order
