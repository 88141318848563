import './footer.css'
export const Footer = () => {

    return (
        <>
            <div className="d-flex justify-content-center footer pt-4">
                <h1>POWERED BY 🍀 <span>Klev. </span>  </h1>
            </div>
        </>

    )
}
export default Footer
