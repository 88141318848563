import './Menu.css'
import Items from "../../component/Items";
export const Menu = () => {
    return (
        <div id="menu" className="mb-5">
            <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-lg-4 px-0">
                        <Items/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Menu
