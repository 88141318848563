import {useNavigate} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import {Skeleton, Typography} from "@mui/material";
import {SearchBar} from "./search/SearchBar";

export const Categories = () => {
    const [mainCategories, setMainCategories] = useState([]);
    const [restaurantCode, setRestaurantCode] = useState(sessionStorage.getItem('restaurantCode') ?? '');

    const backendApiUrl = process.env.REACT_APP_BASE_API_URL

    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const [loading, setLoading] = useState(false)

    const getMainCategories = async () => {
        setLoading(true)
        return (axios.get(backendApiUrl + 'restaurant/' + restaurantCode + '/mainCategories')
                .then((response) => {
                    setMainCategories(response.data.data.map((item: { logo: string, title: string }) => (
                        {
                            ...item,
                            logo: backendUrl + item.logo
                        }
                    )))
                    setLoading(false)

                })
                .catch((error) => {
                    setLoading(false)
                })
        )
    };
    const navigate = useNavigate();

    const navigateToSubCategories = (id: string) => {
        sessionStorage.setItem('categoryId', id)
        navigate('/subCategory')
    }

    useEffect(() => {
        getMainCategories();
    }, [])

    return (
        <div id="main-category">
            <div className="container-fluid px-0">

                {!loading &&
                <h2 className="px-0 pb-3 pt-5"> MAIN CATEGORIES</h2>
                }
                <div className="">
                    {mainCategories.map((mainCategory: { title: string, logo: string, id: string }) => {
                        return (
                            <div onClick={() => navigateToSubCategories(mainCategory.id)} className="row px-3">
                                <div className="my-2 d-flex align-items-center px-0 overlay">
                                    {loading ? (
                                        <> </>
                                    ) : (
                                        <img src={mainCategory?.logo} alt=""/>
                                    )}
                                    {/*<div className="layer"></div>*/}
                                    <div className="category-content">
                                        {/*<p>{mainCategory?.title}</p>*/}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Categories
