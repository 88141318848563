import {Link, useNavigate} from "react-router-dom";
import "./ItemDetials.css";
import SpecialInstructions, {IUpgrade} from "../../component/ItemSpecialInstruction/SpecialInstructions";
import {useEffect, useState} from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const ItemDetails = () => {
    const [itemId, setItemId] = useState(sessionStorage.getItem("itemId"));

    const initialItemDetailState = {
        id: null,
        title: "",
        description: "",
        price: 0,
        image: "",
    };

    let itemPrice = 0;

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const backendApiUrl = process.env.REACT_APP_BASE_API_URL;

    const [itemDetails, setItemDetails] = useState(initialItemDetailState);
    const [upgrades, setUpgrades] = useState([]);
    const [sides, setSides] = useState([])
    const [sauces, setSauces] = useState([])
    const [itemOrderQuantity, setItemOrderQuantity] = useState(Number);

    const [loading, setLoading] = useState(true);
    const [removeIngredients, setRemoveIngredients] = useState([]);
    const [color, setColor] = useState("");

    const navigate = useNavigate();

    const navigateToOrderPage = (upgrade: any, remove: any) => {
        sessionStorage.setItem("upgrades", upgrade);
        sessionStorage.setItem("removeIngredients", remove);
        navigate("/order");
    };
    const goBack = () => {
        navigate(-1);
    };
    // const addToOrder = (clickedItem: CartItemType) => {
    //     setOrder((itemExist) => {
    //         const isItemInCart = itemExist.find((itemDetails) => itemDetails.id === clickedItem.id);
    //         if (isItemInCart) {
    //             return itemExist.map((itemDetails) =>
    //                 ...itemDetails
    //             );
    //         }
    //         // first time item in cart
    //         return [...prev, { ...clickedItem, amount: 1 }];
    //     });
    // };

    useEffect(() => {
        const items = sessionStorage.getItem("previous_items")
            ? JSON.parse(sessionStorage.getItem("previous_items") ?? "")
            : [];
        if (items.length > 0) {
            setItemOrderQuantity(items.length);
        }
        getItemDetails();
    }, []);

    const getItemDetails = async () => {
        return axios
            .get(backendApiUrl + "item/" + itemId)
            .then((response) => {
                setLoading(true);
                setItemDetails({
                    id: response.data.data.id,
                    title: response.data.data.item_name,
                    description: response.data.data.item_description,
                    price: parseFloat(response.data.data.item_price),
                    image: response.data.data.item_image,
                });
                if (response.data.data.upgrades) {
                    setUpgrades(
                        response.data.data.upgrades.map(
                            (upgrade: { ingredient: string; price: string }) => ({
                                ...upgrade,
                            })
                        )
                    );
                }
                if (response.data.data.sauces) {
                    setSauces(
                        response.data.data.sauces.map(
                            (sauce: { sauce_name: string; price: string }) => ({
                                ...sauce,
                            })
                        )
                    );
                }
                if (response.data.data.sides) {
                    setSides(
                        response.data.data.sides.map(
                            (side: { ingredient: string; price: string }) => ({
                                ...side,
                            })
                        )
                    );
                }
                if (response.data.data.removables) {
                    setRemoveIngredients(
                        response.data.data.removables.map(
                            (remove: { ingredient: string }) => ({
                                ...remove,
                            })
                        )
                    );
                }

                setLoading(false);
            })
            .catch((error) => {
            });
    };

    return (
        <div id="itemDetails" className="container-fluid mb-5 px-0 overflow-hidden">
            <div className="row d-flex justify-content-center slider">
                <div className="col-12 col-lg-4 px-0">
                    {!loading && (
                        <>
                            {itemDetails.image !== null && (
                                <img
                                    src={backendUrl + itemDetails.image}
                                    alt=""
                                    className="sliderImage"
                                />
                            )}

                            <div className="slider-content">
                                <div onClick={goBack} className="back">
                                    <img src="/svgs/back.svg" alt=""/>
                                </div>
                                <h1 className="pt-4 text-center"></h1>

                                <div className="mt-2">
                                    <button
                                        type="button"
                                        value=""
                                        className="cart pt-2 mt-3 cart-bg"
                                        onClick={() =>
                                            navigateToOrderPage(removeIngredients, upgrades)
                                        }
                                    >
                                        <img src="/svgs/basket.svg" alt="" width="40" height="40" className="px-1"/>
                                        <span className="badge"> {itemOrderQuantity}</span>
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="">
                        {!loading && (
                            <div className="item-section px-4 pb-4">
                                <h2 className="pt-5">{itemDetails.title}</h2>
                                <p> {itemDetails.description}</p>
                                <div className="border-bottom-items"/>
                                <div className="d-flex align-items-center mt-3">
                                    <p className="price mb-0">$ {itemDetails.price} </p>
                                    <span className="slogan px-2">
                    All our prices are in daily rate USD inclusive of VAT
                  </span>
                                </div>
                            </div>
                        )}

                        {!loading && (
                            <SpecialInstructions
                                upgrades={upgrades}
                                removeIngredients={removeIngredients}
                                itemDetails={itemDetails}
                                sides={sides}
                                sauces={sauces}
                            />
                        )}
                        <div></div>
                    </div>
                </div>
                <Backdrop
                    sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>
        </div>
    );
};

export default ItemDetails;
