import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const SubCategories = (Props: { onSubChange(id: string): void }) => {
    const backendApiUrl = process.env.REACT_APP_BASE_API_URL;
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const navigate = useNavigate();

    const [subCategories, setSubCategories] = useState([]);
    const [restaurantCode, setRestaurantCode] = useState(
        sessionStorage.getItem("restaurantCode") ?? ""
    );
    const [categoryId, setCategoryId] = useState(
        sessionStorage.getItem("categoryId")
    );
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(""); // Track selected subCategory ID
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getSubCategories();
    }, []);

    const getSubCategories = async () => {
        return axios
            .get(
                backendApiUrl + "restaurant/" + restaurantCode + "/subCategories" + "/" + categoryId
            )
            .then((response) => {
                setSubCategories(
                    response.data.data.map((item: { logo: string; title: string; create_code: string; id: string }) => ({
                        ...item,
                        logo: backendUrl + item.logo,
                    }))
                );
            })
            .catch((error) => {});
    };

    const navigateToMenuItems = (title: string, id: string) => {
        sessionStorage.setItem("subCategoryTitle", title);
        sessionStorage.setItem("subCategoryId", id);
        setSelectedSubCategoryId(id);
        Props.onSubChange(id);
    };

    return (
        <>
            {subCategories.map((subCategory: { title: string; logo: string; id: string }) => {
                return (
                    <div
                        key={subCategory.id}
                        onClick={() => navigateToMenuItems(subCategory.title, subCategory.id)}
                        className={`col-4 subcategory mb-3 px-0 ${
                            selectedSubCategoryId === subCategory.id ? "selected" : ""
                        }`}
                    >
                        {loading ? (
                            <></>
                        ) : (
                            <>
                                <img src={subCategory.logo} alt="" className="subCategoryImage" />
                                <p
                                    className={`categoryName pt-2 mb-0 ${
                                        selectedSubCategoryId === subCategory.id ? "selected-category" : ""
                                    }`}
                                >
                                    {subCategory.title}
                                </p>
                            </>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default SubCategories;
