import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import SubCategories from "./SubCategories";
import {SearchBar} from "./search/SearchBar";
import Tooltip from '@mui/material/Tooltip';

export const Items = () => {
    const backendApiUrl = process.env.REACT_APP_BASE_API_URL;

    const initialItemState = {
        id: null,
        title: "",
    };

    const [data, setData] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState(
        sessionStorage.getItem("subCategoryId")
    );
    const [itemOrderQuantity, setItemOrderQuantity] = useState(Number);
    const [restaurantCode, setRestaurantCode] = useState(
        sessionStorage.getItem("restaurantCode") ?? ""
    );
    const [loading, setLoading] = useState(false);
    const [color, setColor] = useState("");
    const [item, setItem] = useState(initialItemState);
    const [items, setItems] = useState([]);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const [results, setResults] = useState([]);

    const handleSearch = async (search: string) => {
        try {
            const response = await axios.get(
                backendApiUrl + `global-search/` + restaurantCode + `?search=${search}`
            );
            setResults(response.data.results);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const items = sessionStorage.getItem("previous_items")
            ? JSON.parse(sessionStorage.getItem("previous_items") ?? "")
            : [];
        if (items.length > 0) {
            setItemOrderQuantity(items.length);
        }
        getItems();
    }, [subCategoryId]);

    const getItems = async () => {
        setLoading(true);
        return axios
            .get(backendApiUrl + "subCategory/" + subCategoryId + "/items")
            .then((response) => {
                setData(response.data.data);
                const items = response.data.data;
                setItem({
                    id: response.data.data.id,
                    title: response.data.data[0].title,
                });
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    // const history = useHistory();
    // function handleClick() {
    //     // eslint-disable-next-line no-restricted-globals
    //     history.goBack();
    // }
    const handleSubCategoriesChanged = (id: string) => {
        setSubCategoryId(id);
    };

    const navigateToItemDetails = (id: string) => {
        sessionStorage.setItem("itemId", id);
        navigate("/itemDetails");
    };
    const navigateToOrderPage = () => {
        sessionStorage.getItem("previous_items");
        navigate("/order");
    };
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <div id="items">
            <div className="container-fluid px-0">
                {data.map((section: any) => (
                    <>
                        <div className="bg-color" style={{
                            backgroundImage: `url(${backendUrl + section.subcategory_banner})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: '25vh',
                            backgroundColor: section.subcategory_banner ? 'transparent' : color,
                        }}>
                            <div className="d-flex justify-content-between align-items-center px-3">
                                <div onClick={goBack} className="back">
                                    <img src="/svgs/back.svg" alt=""/>
                                </div>
                                {/*{item.title && (*/}
                                {/*    <h1 className="pt-3 mb-0 title">{item.title}</h1>*/}
                                {/*)}*/}
                                <div className="mt-2">
                                    <button
                                        type="button"
                                        value=""
                                        className="cart cart-bg"
                                        onClick={() => navigateToOrderPage()}
                                    >
                                        <img src="/svgs/basket.svg" alt=""/>
                                        <span className="badge"> {itemOrderQuantity}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="px-0 category-slider pt-4">
                            <SubCategories onSubChange={handleSubCategoriesChanged}/>
                        </div>
                        <div className="px-3 mt-4">
                            <SearchBar/>
                        </div>
                        {section.header.length === 0 ? (
                            <div>
                                <div className="bg-color">
                                    <div className="d-flex justify-content-between align-items-center px-3">
                                        <div onClick={goBack} className="back">
                                            <img src="/svgs/back.svg" alt=""/>
                                        </div>
                                        <h1 className="pt-3 mb-0 title"></h1>
                                        <div className="mt-2">
                                            <button
                                                type="button"
                                                value=""
                                                className="cart"
                                                onClick={() => navigateToOrderPage()}
                                            >
                                                <img src="/svgs/basket.svg" alt=""/>
                                                <span className="badge"> {itemOrderQuantity}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="px-0 category-slider">
                                        <SubCategories onSubChange={handleSubCategoriesChanged}/>
                                    </div>
                                </div>
                                <h1 className="emptyData">The Category is Empty </h1>
                            </div>
                        ) : (
                            section.header.map((header: any) => (
                                <>
                                    {/* <div className="bg-color">
                                                    <div className="d-flex justify-content-between align-items-center px-3">
                                                        <div onClick={goBack} className="back">
                                                            <img src='/svgs/back.svg' alt=""/>
                                                        </div>
                                                        <h1 className="pt-3 mb-0 title"> {header?.title}</h1>
                                                        <button type="button" value="" className="cart"
                                                                onClick={() => navigateToOrderPage()}><img
                                                            src='/svgs/basket.svg' alt=""/>
                                                            <span className="badge"> {itemOrderQuantity}</span>
                                                        </button>
                                                    </div>
                                                    <div className="px-0 category-slider">
                                                        <SubCategories onSubChange={handleSubCategoriesChanged}/>
                                                    </div>
                                                </div>
                                                <div className="px-3 mt-4">
                                                    <SearchBar/>
                                                </div> */}

                                    <h2 className="px-0 pb-3 pt-5 mb-0 px-3">{header?.title}</h2>

                                    <div>
                                        {header.items.map((items: any) => (
                                            <>
                                                <div>
                                                    {/* {items.item_name.length > 0 ? (
                                                        <h3 className="mb-0 mt-5 px-3">
                                                          {items.item_name}
                                                        </h3>
                                                      ) : null} */}
                                                    <div
                                                        onClick={() => navigateToItemDetails(items.id)}
                                                        className="row px-4 mt-5 py-4 mx-3 item-card"
                                                    >
                                                        <div className="col-8 px-0">
                                                            <h4 className="mb-0">{items.item_name}</h4>
                                                            <p className="mb-0"> {items.item_description}</p>
                                                            <p className="price pt-4">
                                                                <span> $</span>
                                                                {items.item_price}
                                                            </p>
                                                            {items.types.map((types: any) => (
                                                                <>
                                                                    {types.logo !== null && (
                                                                        <Tooltip title={types.type}>
                                                                            <img
                                                                                src={backendUrl + types.logo}
                                                                                alt=""
                                                                                className="itemType"
                                                                            />
                                                                        </Tooltip>

                                                                    )}
                                                                </>
                                                            ))}
                                                        </div>
                                                        <div className="col-4">
                                                            {items.item_image !== null && (
                                                                <img
                                                                    src={backendUrl + items.item_image}
                                                                    alt=""
                                                                    className="itemImage"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                    {header.titles.map((subTitles: any) => (
                                        <>
                                            {/*{subTitles.title ? <h3 className="mb-0 mt-5"> {subTitles.title}</h3> : null}*/}
                                            {/*{subTitles.title && <h3 className="mb-0 mt-5">{subTitles.title}</h3>}*/}

                                            {subTitles.items.length > 0 ? (
                                                <h3 className="mb-0 mt-5 px-3">{subTitles.title}</h3>
                                            ) : null}

                                            {subTitles.items.length > 0 ? (
                                                <div className="border-bottom-items mx-3"/>
                                            ) : null}

                                            {subTitles.items.map((items: any) => (
                                                <div
                                                    onClick={() => navigateToItemDetails(items.id)}
                                                    className="row px-4 mt-5 py-4 mx-3 item-card"
                                                >
                                                    <div className="col-8 px-0">
                                                        <div className="d-flex align-items-center pb-1">
                                                            <h4 className="mb-0">{items.item_name}</h4>
                                                            {items.nutrition_information && (
                                                                <span
                                                                    className="pl-1">({items.nutrition_information})</span>
                                                            )
                                                            }
                                                        </div>
                                                        <p className="mb-0"> {items.item_description}</p>
                                                        <p className="price pt-4">
                                                            <span> $</span>
                                                            {items.item_price}
                                                        </p>

                                                        {items.types.map((types: any) => (
                                                            <>
                                                                {types.logo !== null && (
                                                                    <Tooltip title={types.type}>
                                                                        <img
                                                                            src={backendUrl + types.logo}
                                                                            alt=""
                                                                            className="itemType"
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                    <div className="col-4">
                                                        {items.item_image !== null && (
                                                            <img
                                                                src={backendUrl + items.item_image}
                                                                alt=""
                                                                className="itemImage"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ))}
                                </>
                            ))
                        )}
                    </>
                ))}
            </div>
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
};

export default Items;
